import React from 'react';
import { useRouter } from 'next/router';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';

import { CompanyWithdrawModalButton } from '../../../../teams/components/modalButtons/CompanyWithdrawModalButton';

import { MenuTitle } from '../MenuTitle';
import { MenuPlainLink } from '../MenuPlainLink';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { AccountPath } from '../../../../accounts/AccountPath';
import { JournalRecordPath } from '../../../../journalRecords/JournalRecordPath';

import {
  accountsKeys,
  smartContractsKeys,
  invoicesKeys,
  words
} from '../../../../../locales/keys';
import { journalRecordsKeys, menuKeys } from '../../../../../locales/keys';

import { AccountsPermissions } from '../../../../accounts/accountsConstants';
import { InvoicesPermissions } from '../../../../invoices/invoicesConstants';
import { JournalRecordsPermissions } from '../../../../journalRecords/journalRecordsConstants';
import { SmartContractsPermissions } from '../../../../smartContracts/smartContractsConstants';
import { SmartContractPath } from '../../../../smartContracts/SmartContractPath';
import { InvoicePath } from '../../../../invoices/InvoicePath';
import startsWith from 'lodash/startsWith';

const invoicesPermissions = [
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_OPEN_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_SENT_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_DRAFT_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_PAID_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_CANCELED_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_REFUNDS_LINK
];

const contractorInvoicesPermissions = [
  InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_CURRENT_INVOICES_LINK,
  InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_OVERDUE_INVOICES_LINK
];

const generalLedgerPermissions = [
  AccountsPermissions.READ_PAYMENTS_BALANCE_SHEET_MENU_INDEX_LINK,
  JournalRecordsPermissions.READ_PAYMENTS_JOURNAL_RECORDS_MENU_INDEX_LINK
];

const smartContractsPermissions = [
  SmartContractsPermissions.READ_PAYMENTS_SMART_CONTRACTS_MENU_INDEX_LINK
];

function DefaultSecondaryMenuPayments() {
  const { pathname } = useRouter();

  const currentUser = useCurrentUser();

  const currentUserCompanyNanoId = currentUser.currentTeam?.nanoId;

  return (
    <SecondaryMenuWrapper i18nTitle={menuKeys.tabs.payments}>
      <CheckPermissions actions={invoicesPermissions}>
        {currentUserCompanyNanoId && !currentUser.client ? (
          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON
            }
          >
            <div className="p-4">
              <CompanyWithdrawModalButton
                companyNanoId={currentUserCompanyNanoId}
                withBalance={currentUser.hasPermissions(
                  InvoicesPermissions.READ_PAYMENTS_MENU_WITHDRAW_MODAL_BUTTON_BALANCE
                )}
              />
            </div>
          </CheckPermissions>
        ) : null}
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.invoices} />

          <CheckPermissions
            action={InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_LINK}
          >
            <MenuPlainLink
              active={
                pathname === InvoicePath.paymentsInvoices() ||
                pathname === `${InvoicePath.paymentsInvoices()}/[slug]`
              }
              href={InvoicePath.paymentsInvoices()}
              i18nText={invoicesKeys.allInvoices}
              icon={IconsEnum.VIEW_LIST}
            />
          </CheckPermissions>

          <CheckPermissions
            action={InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_OPEN_LINK}
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesOpenFilter()
              )}
              href={InvoicePath.paymentsInvoicesOpenFilter()}
              i18nText={words.open}
              icon={IconsEnum.EXCLAMATION_CIRCLE}
            />
          </CheckPermissions>

          <CheckPermissions
            action={InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_SENT_LINK}
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesSentFilter()
              )}
              href={InvoicePath.paymentsInvoicesSentFilter()}
              i18nText={words.sent}
              icon={IconsEnum.TICKET_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_DRAFT_LINK}
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesDraftFilter()
              )}
              href={InvoicePath.paymentsInvoicesDraftFilter()}
              i18nText={words.draft}
              icon={IconsEnum.PENCIL_ALT_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_PAID_LINK}
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesPaidFilter()
              )}
              href={InvoicePath.paymentsInvoicesPaidFilter()}
              i18nText={words.paid}
              icon={IconsEnum.CHECK}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_CANCELED_LINK
            }
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesCanceledFilter()
              )}
              href={InvoicePath.paymentsInvoicesCanceledFilter()}
              i18nText={words.cancel}
              icon={IconsEnum.RECEIPT_REFUND}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_INVOICES_REFUNDS_LINK
            }
          >
            <MenuPlainLink
              active={startsWith(
                pathname,
                InvoicePath.paymentsInvoicesRefundsFilter()
              )}
              href={InvoicePath.paymentsInvoicesRefundsFilter()}
              i18nText={words.refunds}
              icon={IconsEnum.ARCHIVE_BOX_X_MARK}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>

      <CheckPermissions actions={contractorInvoicesPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={invoicesKeys.performerInvoices} />

          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_CURRENT_INVOICES_LINK
            }
          >
            <MenuPlainLink
              active={
                pathname ===
                InvoicePath.paymentsContractorInvoicesCurrentFilter()
              }
              href={InvoicePath.paymentsContractorInvoicesCurrentFilter()}
              i18nText={words.current}
              icon={IconsEnum.EXCLAMATION_CIRCLE}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_OVERDUE_INVOICES_LINK
            }
          >
            <MenuPlainLink
              active={
                pathname ===
                InvoicePath.paymentsContractorInvoicesOverdueFilter()
              }
              href={InvoicePath.paymentsContractorInvoicesOverdueFilter()}
              i18nText={words.overdue}
              icon={IconsEnum.FIRE_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              InvoicesPermissions.READ_PAYMENTS_MENU_CONTRACTOR_INVOICES_LINK
            }
          >
            <MenuPlainLink
              active={pathname === InvoicePath.paymentsContractorInvoices()}
              href={InvoicePath.paymentsContractorInvoices()}
              i18nText={invoicesKeys.allInvoices}
              icon={IconsEnum.VIEW_LIST}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>

      <CheckPermissions actions={generalLedgerPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.generalLedger} />

          <CheckPermissions
            action={
              AccountsPermissions.READ_PAYMENTS_BALANCE_SHEET_MENU_INDEX_LINK
            }
          >
            <MenuPlainLink
              active={pathname === AccountPath.paymentsBalanceSheet()}
              href={AccountPath.paymentsBalanceSheet()}
              i18nText={accountsKeys.balanceSheet}
              icon={IconsEnum.LIST_BULLET_ALT}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              JournalRecordsPermissions.READ_PAYMENTS_JOURNAL_RECORDS_MENU_INDEX_LINK
            }
          >
            <MenuPlainLink
              active={pathname === JournalRecordPath.paymentsJournalRecords()}
              href={JournalRecordPath.paymentsJournalRecords()}
              i18nText={journalRecordsKeys.transactions}
              icon={IconsEnum.SWITCH_HORIZONTAL_SOLID}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>

      <CheckPermissions actions={smartContractsPermissions}>
        <div className="p-4">
          <MenuTitle i18nText={menuKeys.links.smartContracts} />

          <CheckPermissions
            action={
              SmartContractsPermissions.READ_PAYMENTS_SMART_CONTRACTS_MENU_INDEX_LINK
            }
          >
            <MenuPlainLink
              active={pathname === SmartContractPath.paymentsSmartContracts()}
              href={SmartContractPath.paymentsSmartContracts()}
              i18nText={smartContractsKeys.allSmartContracts}
              icon={IconsEnum.VIEW_LIST_SOLID}
            />
          </CheckPermissions>
        </div>
      </CheckPermissions>
    </SecondaryMenuWrapper>
  );
}

export default DefaultSecondaryMenuPayments;
