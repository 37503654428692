import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { useCompanyCashAccount } from '../../../../accounts/hooks/useCompanyCashAccount';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { Loading } from '../../../../../helpers/Loading';
import { TooltipAlertMessage } from '../../../../../helpers/TooltipAlertMessage';

import { accountsKeys } from '../../../../../locales/keys';

interface CompanyWithdrawModalButtonProps {
  companyNanoId: TeamNanoID;
  withBalance?: boolean;
}

function CompanyWithdrawModalButton({
  companyNanoId,
  withBalance
}: CompanyWithdrawModalButtonProps) {
  const {
    companyCashAccount,
    companyCashAccountErrorMessage,
    companyCashAccountFetched
  } = useCompanyCashAccount({ companyNanoId });

  return (
    <div className="group relative -m-2 p-2 flex items-center space-x-4 rounded-xl bg-blue-500 bg-opacity-0 hover:bg-opacity-10 focus-within:ring-base">
      <div className="shrink-0 flex items-center justify-center h-16 w-16 rounded-lg bg-blue-500">
        <Icon
          icon={IconsEnum.WITHDRAWAL_OUTLINE}
          className="h-12 w-12 text-white"
        />
      </div>
      <div>
        {withBalance ? (
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
            <Translate id={accountsKeys.balance} />
            {':'}
            <br />
            <Loading loaded={companyCashAccountFetched} size="xs">
              {companyCashAccountErrorMessage ? null : (
                <MoneyHelper value={companyCashAccount?.balance || 0} />
              )}
              <TooltipAlertMessage message={companyCashAccountErrorMessage} />
            </Loading>
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default CompanyWithdrawModalButton;
