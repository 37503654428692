import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  CreatedAt,
  FetchItemFetched,
  FetchItemsCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSerializer,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  MoneyType,
  NanoID,
  NanoIDFilter,
  StringArrayFilter,
  CreatedAtFilter,
  UpdatedAt,
  UUID,
  FetchItemsClearItemsFilters,
  NanoIdFilterType,
  TextFilterType,
  StringFilter,
  DateFilterType,
  IdOrNanoFilterType,
  IdFilterType,
  FetchItemsGqlQuery,
  ID
} from '../../types';

import {
  AccountID,
  AccountNanoID,
  AccountBalance,
  AccountAccountTypeNanoID,
  AccountAccountTypeIncreaseSides,
  AccountAccountTypeChartNumber,
  AccountAccountTypeName,
  AccountAccountTypeClientName,
  AccountAccountTypePerformerName,
  AccountAccountTypeShortName,
  AccountGeneralLedgerId
} from '../accounts/accountsTypes';
import {
  InvoiceStatus,
  InvoiceNanoID,
  InvoiceClientId,
  InvoiceID
} from '../invoices/invoicesTypes';
import { InvoiceTypes } from '../fin/finTypes';

export const enum JournalRecordSides {
  DEBIT = 'debit',
  CREDIT = 'credit'
}

export type JournalRecordID = number;
export type JournalRecordUUID = UUID;
export type JournalRecordNanoID = NanoID;
export type JournalRecordCreatedAt = CreatedAt;
export type JournalRecordUpdatedAt = UpdatedAt;
export type JournalRecordAmount = MoneyType;
export type JournalRecordSide = JournalRecordSides;

export type JournalRecordAccountId = AccountID;
export type JournalRecordAccountNanoID = AccountNanoID;
export type JournalRecordAccountBalance = AccountBalance;
export type JournalRecordGeneralLedgerId = AccountGeneralLedgerId;
export type JournalRecordAccountAccountTypeIncreaseSides =
  AccountAccountTypeIncreaseSides;
export type JournalRecordAccountAccountTypeNanoID = AccountAccountTypeNanoID;
export type JournalRecordAccountAccountTypeChartNumber =
  AccountAccountTypeChartNumber;
export type JournalRecordAccountAccountTypeName = AccountAccountTypeName;
export type JournalRecordAccountAccountTypeClientName =
  AccountAccountTypeClientName;
export type JournalRecordAccountAccountTypePerformerName =
  AccountAccountTypePerformerName;
export type JournalRecordAccountAccountTypeShortName =
  AccountAccountTypeShortName;

export type JournalRecordJournalEntryId = ID;
export type JournalRecordJournalEntryAccountingTransactionID = ID;
export type JournalRecordJournalEntryAccountingTransactionInvoiceID = InvoiceID;
export type JournalRecordJournalEntryAccountingTransactionInvoiceStatus =
  InvoiceStatus;

export type JournalRecordJournalEntryAccountingTransactionInvoiceType =
  InvoiceTypes;
export type JournalRecordJournalEntryAccountingTransactionInvoiceNanoID =
  InvoiceNanoID;
export type JournalRecordJournalEntryAccountingTransactionInvoiceClientId =
  InvoiceClientId;

export type JournalRecordJournalEntryAccountingTransactionTransactionEventName =
  string;
export type JournalRecordJournalEntryAccountingTransactionItcID = number;
export type JournalRecordJournalEntryAccountingTransactionItcTrigger = string;
export type JournalRecordJournalEntryAccountingTransactionItcValue = string;

export type FetchJournalRecordsCacheKey = FetchItemsCacheKey;

export type FetchFinJournalRecordsFiltersToPersist =
  (keyof FetchFinJournalRecordsFilters)[];

export enum FetchJournalRecordsFilterFields {
  COMPANY_NANO_ID = 'companyNanoId',
  ACCOUNT_TYPE_NANO_ID = 'accountTypeNanoId',
  PROJECT_NANO_ID = 'projectNanoId',
  INVOICE_NANO_ID = 'invoiceNanoId',
  ACCOUNT_TYPE_CHART_NUMBERS = 'accountTypeChartNumbers',
  CREATED_AT_GTE = 'createdAtGte',
  CREATED_AT_LTE = 'createdAtLte',
  INCOME_STATEMENT_RECORD_TYPE = 'incomeStatementRecordType',
  USER_NANO_ID = 'userNanoId',
  CREATED_AT = 'createdAt',
  INVOICE_ID_OR_NANOID = 'invoiceIdOrNanoId',
  PROJECT_ID_OR_NANOID = 'projectIdOrNanoId',
  TASK_ID_OR_NANOID = 'taskIdOrNanoId',
  EVENT_NAME = 'eventName',
  ACCOUNT_TYPE_CHART_NUMBER = 'accountTypeChartNumber',
  GENERAL_LEDGER_ID = 'generalLedgerId',
  COMPANY_NAME = 'companyName',
  ACCOUNTING_TRANSACTION_ID = 'accountingTransactionId'
}

export interface FetchFinJournalRecordsFilters {
  id?: IdFilterType;
  companyNanoId?: NanoIdFilterType;
  accountTypeNanoId?: NanoIdFilterType;
  invoiceIdOrNanoId?: IdOrNanoFilterType;
  accountNanoId?: NanoIdFilterType;
  eventName?: TextFilterType;
  projectIdOrNanoId?: IdOrNanoFilterType;
  taskIdOrNanoId?: IdOrNanoFilterType;
  accountTypeChartNumber?: TextFilterType;
  createdAt?: DateFilterType;
  accountingTransactionId?: IdFilterType;
  projectNanoId?: NanoIdFilterType;
  taskNanoId?: NanoIdFilterType;
  companyName?: TextFilterType;
  generalLedgerId?: IdFilterType;
}

export interface FetchJournalRecordsFilters {
  id?: IdFilterType;
  invoiceNanoId?: NanoIDFilter;
  companyNanoId?: NanoIDFilter;
  accountTypeNanoId?: NanoIDFilter;
  projectNanoId?: NanoIDFilter;
  taskNanoId?: NanoIDFilter;
  accountTypeChartNumbers?: StringArrayFilter;
  createdAtGte?: CreatedAtFilter;
  createdAtLte?: CreatedAtFilter;
  incomeStatementRecordType?: StringFilter;
  userNanoId?: NanoIDFilter;
}

export const enum FetchJournalRecordsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export const enum FetchJournalRecordsSortableFields {
  CREATED_AT = 'createdAt'
}

export const enum JournalRecordFields {
  NAME = 'name',
  ID = 'id'
}

export type FetchJournalRecordsSort = FetchItemsSort;
export type FetchJournalRecordsSortJournalRecords = FetchItemsSortItems;
export type FetchJournalRecordsPage = FetchItemsPage;
export type FetchJournalRecordsLimit = FetchItemsLimit;
export type FetchJournalRecordsSerializer = FetchItemsSerializer;
export type FetchJournalRecordsErrorMessage = FetchItemsErrorMessage;
export type FetchJournalRecordsFetched = FetchItemsFetched;
export type FetchJournalRecordFetched = FetchItemFetched;
export type FetchJournalRecordsIsPlaceholderData = FetchItemsIsPlaceholderData;
// export type FetchJournalRecordIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchJournalRecordsPaginateJournalRecords = FetchItemsPaginateItems;
export type FetchJournalRecordsPrefetchJournalRecords = FetchItemsPrefetchItems;
export type FetchJournalRecordsTotalCount = FetchItemsTotalCount;
export type FetchJournalRecordsFilterJournalRecords =
  FetchItemsFilterItems<FetchJournalRecordsFilters>;

export type FetchFinJournalRecordsFilterJournalRecords =
  FetchItemsFilterItems<FetchFinJournalRecordsFilters>;

export type FetchJournalRecordsClearJournalRecordsFilters =
  FetchItemsClearItemsFilters;

export type FetchJournalRecordsGqlQuery = FetchItemsGqlQuery;

export type FetchJournalRecordsCheckedAll = CheckedAll;
export type FetchJournalRecordsOnCheckAll = OnCheckAll;
export type FetchJournalRecordsChecked = Checked;
export type FetchJournalRecordsCheckedHash = CheckedHashItem;
export type FetchJournalRecordsOnSetCheckedIds = OnSetCheckedIds;

export type ChangeJournalRecordsFiltersFunc = (
  changedFilters: FetchJournalRecordsFilters,
  removeFilters?: string[]
) => void;

export type ChangeFinJournalRecordsFiltersFunc = (
  changedFilters: FetchFinJournalRecordsFilters,
  removeFilters?: string[]
) => void;

export type JournalRecordProjectNanoIdFilter = NanoIdFilterType;
export type JournalRecordUserNanoIdFilter = NanoIdFilterType;
export type JournalRecordAccountTypeNanoIdFilter = NanoIdFilterType;
export type JournalRecordCreatedAtGteFilter = string;
export type JournalRecordCreatedAtLteFilter = string;
export type JournalRecordIncomeStatementRecordTypeFilter = TextFilterType;
export type JournalRecordAccountTypeChartNumbersFilter = TextFilterType;

export type FetchJournalRecordsFiltersFunc = (
  nextFilters: FetchJournalRecordsFilters
) => void;

export type FetchFinJournalRecordsFiltersFunc = (
  nextFilters: FetchFinJournalRecordsFilters
) => void;
