import { FetchJournalRecordsSortTypes } from './journalRecordsTypes';

export const INITIAL_JOURNAL_RECORDS_FILTERS = {};
export const INITIAL_JOURNAL_RECORDS_PAGE = 1;
export const INITIAL_JOURNAL_RECORDS_SORT = [
  FetchJournalRecordsSortTypes.CREATED_AT_DESC
];
export const INITIAL_JOURNAL_RECORDS_LIMIT = 50;

export enum JournalRecordsPermissions {
  READ_PAYMENTS_JOURNAL_RECORDS_MENU_INDEX_LINK = 'read_payments_journal_records_menu_index_link',
  READ_ADMIN_MENU_ACCOUNTING_TRANSACTIONS_LINK = 'read_admin_menu_accounting_transactions_link',
  READ_PAYMENTS_JOURNAL_RECORDS_INDEX_PAGE = 'read_payments_journal_records_index_page',
  READ_PAYMENTS_JOURNAL_RECORDS_TABLE = 'read_payments_journal_records_table',
  READ_JOURNAL_RECORDS_SUB_HEADER_ACCOUNT_NANO_ID = 'read_journal_records_sub_header_account_nano_id',
  READ_JOURNAL_RECORDS_TABLE_SELECTED_FIELD = 'read_journal_records_table_selected_field',
  READ_JOURNAL_RECORDS_TABLE_CREATED_AT_FIELD = 'read_journal_records_table_created_at_field',
  READ_JOURNAL_RECORDS_TABLE_EVENT_FIELD = 'read_journal_records_table_event_field',
  READ_JOURNAL_RECORDS_TABLE_GENERAL_LEDGER_ID_FIELD = 'read_journal_records_table_general_ledger_id_field',
  READ_JOURNAL_RECORDS_TABLE_ACCOUNT_FIELD = 'read_journal_records_table_account_field',
  READ_JOURNAL_RECORDS_TABLE_INVOICE_ID_FIELD = 'read_journal_records_table_invoice_id_field',
  READ_JOURNAL_RECORDS_TABLE_AMOUNT_CENTS_FIELD = 'read_journal_records_table_amount_cents_field',
  READ_JOURNAL_RECORDS_TABLE_ACCOUNT_BALANCE_CENTS_FIELD = 'read_journal_records_table_account_balance_cents_field',
  READ_JOURNAL_RECORDS_TABLE_DEBIT_AMOUNT_CENTS_FIELD = 'read_journal_records_table_debit_amount_cents_field',
  READ_JOURNAL_RECORDS_TABLE_CREDIT_AMOUNT_CENTS_FIELD = 'read_journal_records_table_credit_amount_cents_field',
  READ_JOURNAL_RECORDS_TABLE_ITC_FIELD = 'read_journal_records_table_itc_field',
  READ_JOURNAL_RECORDS_TABLE_TRANSACTION_ID_FIELD = 'read_journal_records_table_transaction_id_field',
  READ_JOURNAL_RECORDS_TABLE_ID_FIELD = 'read_journal_records_table_id_field',
  READ_JOURNAL_RECORDS_TABLE_PROJECT_FIELD = 'read_journal_records_table_project_field',
  READ_JOURNAL_RECORDS_TABLE_TASK_FIELD = 'read_journal_records_table_task_field',
  READ_JOURNAL_RECORDS_IMPORT_BUTTON = 'read_journal_records_import_button',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_CREATED_AT_FIELD = 'read_journal_records_income_statement_table_created_at_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_AMOUNT_FIELD = 'read_journal_records_income_statement_table_amount_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_ACCOUNT_TYPE_CHART_NUMBER_FIELD = 'read_journal_records_income_statement_table_account_type_chart_number_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_ACCOUNT_TYPE_NAME_FIELD = 'read_journal_records_income_statement_table_account_type_name_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_TRANSACTION_EVENT_NAME_FIELD = 'read_journal_records_income_statement_table_transaction_event_name_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_TABLE_PROJECT_AND_TASK_FIELD = 'read_journal_records_income_statement_table_project_and_task_field',
  READ_JOURNAL_RECORDS_INCOME_STATEMENT_CREATED_AT_FILTER = 'read_journal_records_income_statement_created_at_filter'
}

export const enum IncomeStatementAccountChartNumbers {
  CHART_NUMBER_3_1_1 = '3.1.1',
  CHART_NUMBER_4_1_2 = '4.1.2',
  CHART_NUMBER_4_3_2 = '4.3.2',
  CHART_NUMBER_5_1_3 = '5.1.3',
  CHART_NUMBER_5_1_7 = '5.1.7',
  CHART_NUMBER_5_2_1 = '5.2.1'
}

export const incomeStatementAccountTypeChartNumbers = [
  IncomeStatementAccountChartNumbers.CHART_NUMBER_3_1_1,
  IncomeStatementAccountChartNumbers.CHART_NUMBER_4_1_2,
  IncomeStatementAccountChartNumbers.CHART_NUMBER_4_3_2,
  IncomeStatementAccountChartNumbers.CHART_NUMBER_5_1_3,
  IncomeStatementAccountChartNumbers.CHART_NUMBER_5_1_7,
  IncomeStatementAccountChartNumbers.CHART_NUMBER_5_2_1
];
