import {
  LifestyleCategoryID,
  LifestyleUUID,
  LifestyleClientID
} from './lifestylesTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class LifestyleCache {
  static indexCacheKey() {
    return 'lifestyles';
  }

  static showCacheKey() {
    return 'lifestyle';
  }

  static categoriesCacheKey() {
    return 'lifestyleCategories';
  }

  static categoriesLibraryCacheKey(clientIdsPart: string) {
    return `lifestyleCategoriesLibrary-${clientIdsPart}`;
  }

  static libraryCacheKey(clientId: LifestyleClientID) {
    return `lifestylesLibrary-${clientId}`;
  }

  static companyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `company-lifestyles-library-${companyNanoId}`;
  }

  static companyLibraryTotalCountCacheKey(companyNanoId: TeamNanoID) {
    return `company-lifestyles-library-total-count-${companyNanoId}`;
  }

  static categoryCacheKey(id: LifestyleCategoryID) {
    return `lifestylesCategory-${id}`;
  }

  static stylesFilterCacheKey() {
    return 'lifestyleStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleStylesLibraryFilter-${clientIdsPart}`;
  }

  static gammasFilterCacheKey() {
    return 'lifestyleGammasFilter';
  }

  static gammasLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleGammasLibraryFilter-${clientIdsPart}`;
  }

  static tonesFilterCacheKey() {
    return 'lifestyleTonesFilter';
  }

  static tonesLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleTonesLibraryFilter-${clientIdsPart}`;
  }

  static materialsFilterCacheKey() {
    return 'lifestyleMaterialsFilter';
  }

  static materialsLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleMaterialsLibraryFilter-${clientIdsPart}`;
  }

  static colorsFilterCacheKey() {
    return 'lifestyleColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientIdsPart: string) {
    return `lifestyleColorsLibraryFilter-${clientIdsPart}`;
  }

  static authorsFilterCacheKey() {
    return 'lifestyleAuthorFilter';
  }

  static authorsLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleAuthorsLibraryFilter-${clientId}`;
  }

  static authorsSelectedFilterCacheKey() {
    return 'lifestyleAuthorsSelectedFilter';
  }

  static showQueryKey(lifestyleUuid: LifestyleUUID) {
    return ['lifestyle', lifestyleUuid];
  }
}
