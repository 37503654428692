import {
  ProductCategoryID,
  ProductUUID,
  ProductClientID,
  ProductSku
} from './productsTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class ProductCache {
  static indexCacheKey() {
    return 'products';
  }

  static showCacheKey() {
    return 'product';
  }

  static categoriesCacheKey() {
    return 'productCategories';
  }

  static skuFilteredCacheKey(sku: ProductSku[]) {
    return `productSkuFiltered-${sku.join('-')}`;
  }

  static categoriesLibraryCacheKey(clientIdsPart: string) {
    return `productCategoriesLibrary-${clientIdsPart}`;
  }

  static libraryCacheKey(clientId: ProductClientID) {
    return `productsLibrary-${clientId}`;
  }

  static companyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `company-products-library-${companyNanoId}`;
  }

  static companyLibraryTotalCountCacheKey(companyNanoId: TeamNanoID) {
    return `company-products-library-total-count-${companyNanoId}`;
  }

  static categoryCacheKey(id: ProductCategoryID) {
    return `productCategories-${id}`;
  }

  static stylesFilterCacheKey() {
    return 'productStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientIdsPart: string) {
    return `productStylesLibraryFilter-${clientIdsPart}`;
  }

  static materialsFilterCacheKey() {
    return 'productMaterialsFilter';
  }

  static materialsLibraryFilterCacheKey(clientIdsPart: string) {
    return `productMaterialsLibraryFilter-${clientIdsPart}`;
  }

  static colorsFilterCacheKey() {
    return 'productColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientIdsPart: string) {
    return `productColorsLibraryFilter-${clientIdsPart}`;
  }

  static brandsFilterCacheKey(categoryId?: ProductCategoryID) {
    return `productBrandsFilter${categoryId ? `-category-${categoryId}` : ''}`;
  }

  static brandsLibraryFilterCacheKey(
    clientIdsPart: string,
    categoryId?: ProductCategoryID
  ) {
    return `productBrandsLibraryFilter-${clientIdsPart}${
      categoryId ? `-category-${categoryId}` : ''
    }`;
  }

  static brandsSelectedFilterCacheKey() {
    return 'productBrandsSelectedFilter';
  }

  static showQueryKey(productUuid: ProductUUID) {
    return ['product', productUuid];
  }
}
