import React from 'react';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';

import {
  TeamShowPageHeaderFinTeam,
  TeamShowPageHeaderTeam
} from '../../../headers/TeamShowPageHeader';

import { TeamProfileSecondaryMenuWrapperHeadTeamAvatar } from './components/TeamProfileSecondaryMenuWrapperHeadTeamAvatar';

import { TeamNameStatus } from '../../../headers/TeamShowPageHeader/components/TeamNameStatus';

import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';

import { TeamTeamTypeEnum } from '../../../../teamsTypes';

interface TeamProfileSecondaryMenuWrapperHeadProps {
  finTeam?: TeamShowPageHeaderFinTeam;
  team: TeamShowPageHeaderTeam;
  loaded: boolean;
}

function TeamProfileSecondaryMenuWrapperHead({
  finTeam,
  team,
  loaded
}: TeamProfileSecondaryMenuWrapperHeadProps) {
  const currentUser = useCurrentUser();

  const isCurrentTeam = team?.nanoId === currentUser.currentTeam?.nanoId;

  const isWorker = team?.teamType === TeamTeamTypeEnum.WORKERS;

  if (isCurrentTeam && isWorker) {
    return null;
  }

  return (
    <div className="flex-shrink-0 h-16 border-b border-gray-200 dark:border-gray-700 relative">
      <div className="absolute inset-0 overflow-hidden">
        <div className="flex flex-row w-full h-full items-center px-6">
          <div className="-mx-4 flex gap-2">
            <LoadingSkeleton
              loaded={loaded}
              count={1}
              width={48}
              height={48}
              className="m-0"
              skeletonClassName="flex"
            >
              <TeamProfileSecondaryMenuWrapperHeadTeamAvatar team={team} />
            </LoadingSkeleton>
            <div className="flex flex-col justify-center flex-1 min-w-px sm:px-2 py-0.5 gap-1">
              <LoadingSkeleton loaded={loaded} count={2} className="m-0">
                <TeamNameStatus team={team} finTeam={finTeam} />
              </LoadingSkeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamProfileSecondaryMenuWrapperHead;
