import React, { Fragment } from 'react';

import {
  TeamClientStatusesEnum,
  TeamTeamTypeEnum
} from '../../../../../teamsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  TeamNameStatusFinTeam,
  TeamNameStatusTeam
} from './TeamNameStatus.types';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  TeamsPermissions,
  teamClientI18nTextStatuses
} from '../../../../../teamsConstants';
import { words } from '../../../../../../../locales/keys';

interface TeamNameStatusProps {
  finTeam?: TeamNameStatusFinTeam;
  team: TeamNameStatusTeam;
}

function TeamNameStatus({ finTeam, team }: TeamNameStatusProps) {
  const siteHref = team?.site
    ? team.site.replace(/^(?!https)/, 'https://')
    : null;

  return (
    <Fragment>
      <h2 className="font-medium text-md line-clamp-2">
        {siteHref ? (
          <LinkHelper href={siteHref} className="hover:underline">
            {team.name}
          </LinkHelper>
        ) : (
          team.name
        )}
      </h2>

      {team.teamType === TeamTeamTypeEnum.CLIENTS ? (
        <div className="flex items-center text-xs gap-4">
          <CheckPermissions
            action={TeamsPermissions.READ_COMPANY_PROFILE_STATUS}
          >
            {team.clientStatus && (
              <div
                className={`flex items-center gap-1 ${
                  team.clientStatus === TeamClientStatusesEnum.ACTIVE
                    ? 'text-green-600'
                    : ''
                }`}
              >
                {team.clientStatus === TeamClientStatusesEnum.ACTIVE && (
                  <Icon
                    icon={IconsEnum.CHECK_CIRCLE_SOLID}
                    className="h-4 w-4"
                  />
                )}

                <Translate id={teamClientI18nTextStatuses[team.clientStatus]} />
              </div>
            )}
          </CheckPermissions>

          <CheckPermissions
            action={TeamsPermissions.READ_COMPANY_PROFILE_FIN_STATUS}
          >
            {finTeam?.bitClientStatus && (
              <div
                className={`flex items-center gap-1 ${
                  finTeam?.bitClientStatus === TeamClientStatusesEnum.ACTIVE
                    ? 'text-green-600'
                    : ''
                }`}
              >
                {finTeam?.bitClientStatus === TeamClientStatusesEnum.ACTIVE && (
                  <Icon
                    icon={IconsEnum.CHECK_CIRCLE_SOLID}
                    className="h-4 w-4"
                  />
                )}

                <Translate
                  id={teamClientI18nTextStatuses[finTeam.bitClientStatus]}
                />
              </div>
            )}
          </CheckPermissions>

          <CheckPermissions action={TeamsPermissions.READ_COMPANY_PROFILE_NDA}>
            {team.nda && (
              <span className="px-1 leading-4 rounded shadow text-2xs bg-yellow-200 text-black">
                <Translate id={words.nda} />
              </span>
            )}
          </CheckPermissions>
        </div>
      ) : (
        <div className="flex items-center text-xs gap-4 max-w-full truncate">
          <div className="text-gray-400 dark:text-gray-500 truncate">
            {team.owner?.financeRoleName}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default TeamNameStatus;
