import {
  Checked,
  CheckedAll,
  CheckAllDisabled,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  BooleanFilter,
  CreatedAt,
  CreateItemCacheKeys,
  CreateItemIsLoading,
  DeleteItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  MoneyType,
  NanoID,
  NanoIDFilter,
  StringFilter,
  UpdatedAt,
  UpdateItemCacheKeys,
  UpdateItemIsLoading,
  UUID
} from '../../types';

export type ItemTypeID = ID;
export type ItemTypeUUID = UUID;
export type ItemTypeNanoID = NanoID;
export type ItemTypeCreatedAt = CreatedAt;
export type ItemTypeUpdatedAt = UpdatedAt;
export type ItemTypeName = string;
export type ItemTypePrice = MoneyType;

export const enum FetchItemTypesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export interface FetchItemTypesFilters {
  companyNanoId?: NanoIDFilter;
  name?: StringFilter;
  withoutCompany?: BooleanFilter;
  withCompany?: BooleanFilter;
}

export type FetchItemTypesCacheKey = FetchItemsCacheKey;
export type FetchItemTypesCacheKeys = FetchItemTypesCacheKey[];

export type FetchItemTypesSort = FetchItemsSort;
export type FetchItemTypesSortItemTypes = FetchItemsSortItems;
export type FetchItemTypesPage = FetchItemsPage;
export type FetchItemTypesLimit = FetchItemsLimit;
export type FetchItemTypesErrorMessage = FetchItemsErrorMessage;
export type FetchItemTypesFetched = FetchItemsFetched;
export type FetchItemTypesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchItemTypesPaginateItemTypes = FetchItemsPaginateItems;
export type FetchItemTypesPrefetchItemTypes = FetchItemsPrefetchItems;
export type FetchItemTypesTotalCount = FetchItemsTotalCount;

export type FetchItemTypesCheckedAll = CheckedAll;
export type FetchItemTypesCheckAllDisabled = CheckAllDisabled;
export type FetchItemTypesOnCheckAll = OnCheckAll;
export type FetchItemTypesChecked = Checked;
export type FetchItemTypesCheckedHash = CheckedHashItem;
export type FetchItemTypesOnSetCheckedIds = OnSetCheckedIds;

export type FetchItemTypesShowItemType = FetchItemsShowItem;
export type FetchItemTypesEditItemType = FetchItemsEditItem;

export const enum FetchItemTypesSortableFields {
  NAME = 'name',
  ITEM_CATEGORY = 'itemCategory',
  COMPANY = 'company',
  PRICE = 'price',
  USER = 'user',
  CREATED_AT = 'createdAt'
}

export const enum ItemTypeFields {
  NAME = 'name',
  ITEM_CATEGORY_ID = 'itemCategoryId',
  COMPANY_NANO_ID = 'companyNanoId',
  PRICE = 'price'
}

export type CreateItemTypeIsLoading = CreateItemIsLoading;
export type CreateItemTypeCacheKeys = CreateItemCacheKeys;

export type UpdateItemTypeIsLoading = UpdateItemIsLoading;
export type UpdateItemTypeCacheKeys = UpdateItemCacheKeys;

export type DeleteItemTypeCacheKeys = DeleteItemCacheKeys;
