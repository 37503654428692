import React, { Fragment } from 'react';

import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';

import { ThreeDStockIndexPageProductsCategoriesNavCategory } from '../../ThreeDStockIndexPageProductsCategoriesNav.types';

import { ThreeDStockIndexPageProductsCategoriesNavHead } from '../ThreeDStockIndexPageProductsCategoriesNavHead';
import { ThreeDStockIndexPageProductsCategoriesNavList } from '../ThreeDStockIndexPageProductsCategoriesNavList';

interface ThreeDStockIndexPageProductsCategoriesNavBodyProps {
  productCategories: ThreeDStockIndexPageProductsCategoriesNavCategory[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
}

function ThreeDStockIndexPageProductsCategoriesNavBody({
  productCategories,
  filtersNavBasePath
}: ThreeDStockIndexPageProductsCategoriesNavBodyProps) {
  return (
    <Fragment>
      <ThreeDStockIndexPageProductsCategoriesNavHead />
      <ThreeDStockIndexPageProductsCategoriesNavList
        productCategories={productCategories}
        filtersNavBasePath={filtersNavBasePath}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNavBody;
