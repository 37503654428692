import React, { memo, ReactNode, useEffect } from 'react';
import cl from 'classnames';

import { SingleUserPageHeaderUser } from '../../../../main/users/components/menus/SingleUserProfileSecondaryMenuWrapperHead/SingleUserProfileSecondaryMenuWrapperHead.types';

import { useMenu } from '../../../hooks/useMenu';

import { SecondaryMenuWrapperBackdrop } from './components/SecondaryMenuWrapperBackdrop';
import { SecondaryMenuWrapperBodyWrapper } from './components/SecondaryMenuWrapperBodyWrapper';
import { SecondaryMenuWrapperCloseButton } from './components/SecondaryMenuWrapperCloseButton';
import { SecondaryMenuWrapperHead } from './components/SecondaryMenuWrapperHead';

import {
  TeamShowPageHeaderFinTeam,
  TeamShowPageHeaderTeam
} from '../../../../main/teams/components/headers/TeamShowPageHeader';
import { TeamProfileSecondaryMenuWrapperHead } from '../../../../main/teams/components/menus/components/TeamProfileSecondaryMenuWrapperHead';
import { SingleUserProfileSecondaryMenuWrapperHead } from '../../../../main/users/components/menus/SingleUserProfileSecondaryMenuWrapperHead';

interface SecondaryMenuWrapperProps {
  i18nTitle?: string;
  finTeam?: TeamShowPageHeaderFinTeam;
  team?: TeamShowPageHeaderTeam;
  user?: SingleUserPageHeaderUser;
  children: ReactNode;
  teamLoaded?: boolean;
  userLoaded?: boolean;
}

function SecondaryMenuWrapper({
  i18nTitle,
  children,
  finTeam,
  team,
  user,
  userLoaded,
  teamLoaded
}: SecondaryMenuWrapperProps) {
  const {
    isSecondaryMenuOpen,
    isSecondaryMenuKeepOpen,
    closeSecondaryMenu,
    onMountSecondarySidebar,
    onUnmountSecondarySidebar
  } = useMenu();

  useEffect(() => {
    onMountSecondarySidebar();
    return () => {
      onUnmountSecondarySidebar();
    };
  }, []);

  return (
    <div
      data-id="nav-secondary-wrap"
      className={cl(
        'absolute h-full w-full z-10 pointer-events-none',
        isSecondaryMenuKeepOpen ? 'xl:relative xl:w-auto' : null
      )}
    >
      <div
        data-id="nav-secondary-inner"
        className={cl(
          'relative z-10 flex h-full pointer-events-auto w-96 4xl:w-112 max-w-full transform transition-transform',
          isSecondaryMenuOpen ? null : '-translate-x-full',
          isSecondaryMenuKeepOpen ? 'xl:transform-none xl:w-80' : null
        )}
      >
        <nav
          data-id="nav-secondary"
          className={cl(
            'border-gray-200 border-r bg-white dark:bg-gray-900 dark:border-gray-700 flex flex-auto flex-col h-full',
            isSecondaryMenuOpen ? 'shadow-xl' : null,
            isSecondaryMenuKeepOpen ? 'xl:shadow-none xl:pl-0' : null
          )}
        >
          {team && (
            <TeamProfileSecondaryMenuWrapperHead
              team={team}
              finTeam={finTeam}
              loaded={teamLoaded}
            />
          )}

          {user && (
            <SingleUserProfileSecondaryMenuWrapperHead
              user={user}
              loaded={userLoaded}
            />
          )}

          {!user && !team ? (
            <SecondaryMenuWrapperHead i18nTitle={i18nTitle} />
          ) : null}

          <SecondaryMenuWrapperBodyWrapper>
            {children}
          </SecondaryMenuWrapperBodyWrapper>
        </nav>
        <SecondaryMenuWrapperCloseButton
          isSecondaryMenuKeepOpen={isSecondaryMenuKeepOpen}
          closeSecondaryMenu={closeSecondaryMenu}
        />
      </div>
      <SecondaryMenuWrapperBackdrop
        isSecondaryMenuOpen={isSecondaryMenuOpen}
        closeSecondaryMenu={closeSecondaryMenu}
      />
    </div>
  );
}

export default memo<SecondaryMenuWrapperProps>(SecondaryMenuWrapper);
