import React from 'react';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { ThreeDStockIndexPageLibraryNav } from '../ThreeDStockIndexPageLibraryNav';
import { ThreeDStockIndexPageProductsCategoriesNav } from '../ThreeDStockIndexPageProductsCategoriesNav';
import { ThreeDStockIndexPageLifestylesCategoriesNav } from '../ThreeDStockIndexPageLifestylesCategoriesNav';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { StockPermissions } from '../../../stockConstants';

import { words } from '../../../../../locales/keys';

import { ProductPath } from '../../../../products/ProductPath';
import { LifestylePath } from '../../../../lifestyles/LifestylePath';

function ThreeDStockIndexPageMenu() {
  return (
    <SecondaryMenuWrapper i18nTitle={words.threeDStock}>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_LIBRARY_SECTION}
      >
        <ThreeDStockIndexPageLibraryNav />
      </CheckPermissions>
      <ThreeDStockIndexPageProductsCategoriesNav
        filtersNavBasePath={ProductPath.index()}
      />
      <ThreeDStockIndexPageLifestylesCategoriesNav
        filtersNavBasePath={LifestylePath.index()}
      />
    </SecondaryMenuWrapper>
  );
}

export default ThreeDStockIndexPageMenu;
