import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import {
  ProductClientID,
  ProductsFiltersNavBasePath
} from '../../../../products/productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../../products/queries/fetchProductCategories.query';

import { useProductCategories } from '../../../../products/hooks/useProductCategories';

import { ThreeDStockIndexPageProductsCategoriesNavBody } from './components/ThreeDStockIndexPageProductsCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getProductClientIdsCacheKeyPart } from '../../../../products/utils/getProductClientIdsCacheKeyPart';

import { ProductCache } from '../../../../products/ProductCache';

interface ThreeDStockIndexPageProductsCategoriesNavProps {
  clientIds?: ProductClientID[];
  filtersNavBasePath: ProductsFiltersNavBasePath;
}

function ThreeDStockIndexPageProductsCategoriesNav({
  clientIds,
  filtersNavBasePath
}: ThreeDStockIndexPageProductsCategoriesNavProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.categoriesCacheKey()
    : ProductCache.categoriesLibraryCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );
  const addInitialFilters = isEmpty(clientIds)
    ? null
    : { productClientIds: clientIds };

  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useProductCategories({ cacheKey, addInitialFilters });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    if (!isEmpty(clientIds)) {
      const parentCategories = uniqBy(
        compact(productCategories.map((category) => category.parent)),
        'id'
      );

      return sortBy([...productCategories, ...parentCategories], 'name');
    }
    return productCategories;
  }, [productCategories, clientIds]);

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <ThreeDStockIndexPageProductsCategoriesNavBody
          productCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNav;
