import { gql } from 'graphql-request';

export const UPDATE_TEAM_QUERY = gql`
  mutation UpdateTeam(
    $uuid: ID!
    $name: String
    $email: String
    $businessEmail: String
    $imageId: ID
    $countryId: ID
    $coldSourceId: ID
    $categoryId: ID
    $companyRevenueId: ID
    $companySizeId: ID
    $site: String
    $address: String
    $facebook: String
    $linkedIn: String
    $twitter: String
    $instagram: String
    $socialNetworks: String
    $stateId: ID
    $stateName: String
    $subCategoryId: ID
    $city: String
    $natureOfLeadId: ID
    $nda: Boolean
    $description: String
    $discount: String
    $additionalDiscount: String
    $tagIds: [ID!]
    $currency: String
    $validateOutput: String
    $companyCrm: String
    $manualTeamStatus: String
    $marketingSourceId: ID
    $leadCreationDate: DateTime
    $locale: String
    $timeZone: String
    $timeFormat: String
    $dateFormat: String
    $operationHours: String
    $referer: String
    $zipCode: String
    $viewPerTask: String
    $revisions: String
    $hourlyRate: String
    $deliveryTime: String
    $respondTime: String
    $source: String
    $workflowTypeId: ID
    $priority: String
    $ownerId: ID
    $holdRate: Float
    $debtLimit: Float
    $accountReceivables: Float
    $deferredPayment: Integer
    $transactionFee: Float
    $provisionForBadDebt: Float
    $provisionForRevision: Float
    $terms: String
    $preferredPaymentMethod: String
    $teamType: String
  ) {
    updateTeam(
      input: {
        uuid: $uuid
        name: $name
        email: $email
        businessEmail: $businessEmail
        imageId: $imageId
        countryId: $countryId
        coldSourceId: $coldSourceId
        categoryId: $categoryId
        companyRevenueId: $companyRevenueId
        companySizeId: $companySizeId
        site: $site
        address: $address
        facebook: $facebook
        linkedIn: $linkedIn
        twitter: $twitter
        instagram: $instagram
        socialNetworks: $socialNetworks
        stateId: $stateId
        stateName: $stateName
        subCategoryId: $subCategoryId
        city: $city
        natureOfLeadId: $natureOfLeadId
        nda: $nda
        description: $description
        discount: $discount
        additionalDiscount: $additionalDiscount
        tagIds: $tagIds
        currency: $currency
        validateOutput: $validateOutput
        companyCrm: $companyCrm
        manualTeamStatus: $manualTeamStatus
        marketingSourceId: $marketingSourceId
        leadCreationDate: $leadCreationDate
        locale: $locale
        timeZone: $timeZone
        timeFormat: $timeFormat
        dateFormat: $dateFormat
        operationHours: $operationHours
        referer: $referer
        zipCode: $zipCode
        viewPerTask: $viewPerTask
        revisions: $revisions
        hourlyRate: $hourlyRate
        deliveryTime: $deliveryTime
        respondTime: $respondTime
        source: $source
        workflowTypeId: $workflowTypeId
        priority: $priority
        ownerId: $ownerId
        holdRate: $holdRate
        debtLimit: $debtLimit
        accountReceivables: $accountReceivables
        deferredPayment: $deferredPayment
        transactionFee: $transactionFee
        provisionForBadDebt: $provisionForBadDebt
        provisionForRevision: $provisionForRevision
        terms: $terms
        preferredPaymentMethod: $preferredPaymentMethod
        teamType: $teamType
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        name
        tagIds
        fullMessages
      }
    }
  }
`;
