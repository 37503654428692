import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import {
  LifestyleClientID,
  LifestylesFiltersNavBasePath
} from '../../../../lifestyles/lifestylesTypes';

import { FetchLifestyleCategoriesQueryResponse } from '../../../../lifestyles/queries/fetchLifestyleCategories.query';

import { useLifestyleCategories } from '../../../../lifestyles/hooks/useLifestyleCategories';

import { ThreeDStockIndexPageLifestylesCategoriesNavBody } from './components/ThreeDStockIndexPageLifestylesCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getLifestyleClientIdsCacheKeyPart } from '../../../../lifestyles/utils/getLifestyleClientIdsCacheKeyPart';

import { LifestyleCache } from '../../../../lifestyles/LifestyleCache';

interface ThreeDStockIndexPageLifestylesCategoriesNavProps {
  clientIds?: LifestyleClientID[];
  filtersNavBasePath: LifestylesFiltersNavBasePath;
}

function ThreeDStockIndexPageLifestylesCategoriesNav({
  clientIds,
  filtersNavBasePath
}: ThreeDStockIndexPageLifestylesCategoriesNavProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.categoriesCacheKey()
    : LifestyleCache.categoriesLibraryCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );
  const addInitialFilters = isEmpty(clientIds)
    ? null
    : { lifestyleClientIds: clientIds };

  const {
    lifestyleCategories,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData
  } = useLifestyleCategories({ cacheKey, addInitialFilters });

  const lifestyleCategoriesWithParents = useMemo<
    FetchLifestyleCategoriesQueryResponse[]
  >(() => {
    if (!isEmpty(clientIds)) {
      const parentCategories = uniqBy(
        compact(lifestyleCategories.map((category) => category.parent)),
        'id'
      );

      return sortBy([...lifestyleCategories, ...parentCategories], 'name');
    }
    return lifestyleCategories;
  }, [lifestyleCategories, clientIds]);

  return (
    <div className="p-4">
      <AlertMessage message={lifestyleCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          lifestyleCategoriesIsPlaceholderData || lifestyleCategoriesFetched
        }
      >
        <ThreeDStockIndexPageLifestylesCategoriesNavBody
          lifestyleCategories={lifestyleCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
