import { useCallback } from 'react';
import compact from 'lodash/compact';

import { UUID, UpdateItemCacheKey } from '../../../../types';
import { TeamFields, TeamNanoID, TeamUUID } from '../../teamsTypes';

import { UPDATE_TEAM_QUERY } from '../../queries/updateTeam.query';
import { UPDATE_TEAM_REAL_DECLINE_REASONS_QUERY } from '../../queries/updateTeamRealDeclineReasons.query';
import { UPDATE_TEAM_SALES_MANAGERS_QUERY } from '../../queries/updateTeamSalesManagers.query';
import { UPDATE_TEAM_ROLES_QUERY } from '../../queries/updateTeamRoles.query';
import { UPDATE_TEAM_PAYMENT_METHODS_QUERY } from '../../queries/updateTeamPaymentMethods.query';
import { UPDATE_TEAM_COMPLEXITIES_QUERY } from '../../queries/updateTeamComplexities.query';
import { UPDATE_TEAM_SCOPE_OF_WORKS_QUERY } from '../../queries/updateTeamScopeOfWorks.query';
import { UPDATE_TEAM_SUB_CATEGORIES_QUERY } from '../../queries/updateTeamSubCategories.query';

import { useUpdateTeam } from '../useUpdateTeam';

import { TeamCache } from '../../TeamCache';

export interface UpdateTeamFieldQueryResponse {
  uuid: TeamUUID;
}

interface UpdateTeamOptions {
  teamUuid: TeamUUID | TeamNanoID;
  fieldName: TeamFields;
  cacheKey?: UpdateItemCacheKey;
}

const updateTeamAction = (fieldName: TeamFields) => {
  switch (fieldName) {
    case TeamFields.REAL_DECLINE_REASON_IDS:
      return 'updateTeamRealDeclineReasons';
    case TeamFields.SALES_MANAGER_IDS:
      return 'updateTeamSalesManagers';
    case TeamFields.ROLE_IDS:
      return 'updateTeamRoles';
    case TeamFields.PAYMENT_METHOD_IDS:
      return 'updateTeamPaymentMethods';
    case TeamFields.COMPLEXITY_IDS:
      return 'updateTeamComplexities';
    case TeamFields.SCOPE_OF_WORK_IDS:
      return 'updateTeamScopeOfWorks';
    case TeamFields.SUB_CATEGORY_IDS:
      return 'updateTeamSubCategories';
    default:
      return 'updateTeam';
  }
};

const updateTeamQuery = (fieldName: TeamFields) => {
  switch (fieldName) {
    case TeamFields.REAL_DECLINE_REASON_IDS:
      return UPDATE_TEAM_REAL_DECLINE_REASONS_QUERY;
    case TeamFields.SALES_MANAGER_IDS:
      return UPDATE_TEAM_SALES_MANAGERS_QUERY;
    case TeamFields.ROLE_IDS:
      return UPDATE_TEAM_ROLES_QUERY;
    case TeamFields.PAYMENT_METHOD_IDS:
      return UPDATE_TEAM_PAYMENT_METHODS_QUERY;
    case TeamFields.COMPLEXITY_IDS:
      return UPDATE_TEAM_COMPLEXITIES_QUERY;
    case TeamFields.SCOPE_OF_WORK_IDS:
      return UPDATE_TEAM_SCOPE_OF_WORKS_QUERY;
    case TeamFields.SUB_CATEGORY_IDS:
      return UPDATE_TEAM_SUB_CATEGORIES_QUERY;
    default:
      return UPDATE_TEAM_QUERY;
  }
};

function useUpdateTeamField<TeamFieldUpdateType>({
  teamUuid,
  fieldName,
  cacheKey
}: UpdateTeamOptions) {
  const {
    updateTeamLoading,
    updateTeamErrorMessage,
    updateTeamReset,
    updateTeam,
    updateTeamError
  } = useUpdateTeam<UpdateTeamFieldQueryResponse>({
    action: updateTeamAction(fieldName),
    query: updateTeamQuery(fieldName),
    cacheKeys: compact([
      TeamCache.showCacheKey(),
      TeamCache.companiesCacheKey(),
      TeamCache.showBillingInfoCacheKey(),
      cacheKey
    ])
  });

  const handleUpdateTeamField = useCallback<
    (updatedValue: TeamFieldUpdateType) => Promise<unknown>
  >(
    (updatedValue) =>
      updateTeam({ uuid: teamUuid as UUID, [fieldName]: updatedValue }),
    [updateTeam, teamUuid, fieldName]
  );

  const handleClearTeamField = useCallback<() => void>(
    () => handleUpdateTeamField(null),
    [handleUpdateTeamField]
  );

  return {
    updateTeamLoading,
    updateTeamErrorMessage,
    updateTeamReset,
    handleUpdateTeamField,
    handleClearTeamField,
    updateTeamError
  };
}

export default useUpdateTeamField;
