import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import take from 'lodash/take';

import { LifestylesFiltersNavBasePath } from '../../../../../../lifestyles/lifestylesTypes';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavItem } from '../ThreeDStockIndexPageLifestylesCategoriesNavItem';
import { ThreeDStockIndexPageLifestylesCategoriesNavAmountButton } from '../ThreeDStockIndexPageLifestylesCategoriesNavAmountButton';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { lifestylesKeys } from '../../../../../../../locales/keys';

interface ThreeDStockIndexPageLifestylesCategoriesNavListProps {
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategory[];
  filtersNavBasePath: LifestylesFiltersNavBasePath;
}

function ThreeDStockIndexPageLifestylesCategoriesNavList({
  lifestyleCategories,
  filtersNavBasePath
}: ThreeDStockIndexPageLifestylesCategoriesNavListProps) {
  const listCategories = useMemo<
    ThreeDStockIndexPageLifestylesCategoriesNavCategory[]
  >(
    () =>
      sortBy(
        filter(lifestyleCategories, (category) => !category.parent),
        'localizedName'
      ),

    [lifestyleCategories]
  );

  const [showAll, setShowAll] = useState(false);

  const allLifestyles = (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <NextPureLinkHelper
          href={filtersNavBasePath}
          className="focus:outline-none flex-1 px-2 text-sm"
          i18nText={lifestylesKeys.all}
        />
      </div>
    </div>
  );

  if (size(listCategories) <= 5) {
    return (
      <>
        {allLifestyles}
        {listCategories.map((category) => (
          <ThreeDStockIndexPageLifestylesCategoriesNavItem
            key={category.id}
            category={category}
            filtersNavBasePath={filtersNavBasePath}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll ? listCategories : take(listCategories, 5);

  return (
    <>
      {allLifestyles}
      {showedCategories.map((category) => (
        <ThreeDStockIndexPageLifestylesCategoriesNavItem
          key={category.id}
          category={category}
          filtersNavBasePath={filtersNavBasePath}
        />
      ))}
      <ThreeDStockIndexPageLifestylesCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavList;
